// vue
import { createApp } from "petite-vue";
// swiper
import Swiper from "swiper/swiper-bundle.esm.js";
import "swiper/swiper-bundle.min.css";
//fancyapps
import "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
// count up
import { CountUp } from "countup.js";
// gsap
import {
  gsap,
  ScrollTrigger,
  ScrollToPlugin,
  MotionPathPlugin,
  MotionPathHelper,
} from "gsap/all";
// bootstrap icons
import "bootstrap-icons/font/bootstrap-icons.css";
// smoothscroll
import SmoothScroll from "smoothscroll-for-websites";
import ScrollMagic from "scrollmagic";

// utilities
function isElementInViewport(el, inCB, outCB, rootMargin) {
  var margin = rootMargin || "-10%";
  function handleIntersect(entries, observer) {
    var entry = entries[0];
    if (entry.isIntersecting) {
      if (inCB && typeof inCB === "function") inCB(el, entry);
    } else {
      if (outCB && typeof outCB === "function") outCB(el, entry);
    }
  }
  var observer = new IntersectionObserver(handleIntersect, {
    rootMargin: margin,
  });
  el ? observer.observe(el) : "";
}

function getElementTop(element) {
  var top = element.offsetTop;
  var parent = element.offsetParent;
  while (parent != null) {
    top += parent.offsetTop;
    parent = parent.offsetParent;
  }
  return top;
}

var initCountUp = function () {
  var countUpFn = function (trigger, stati) {
    let statiEl = document.querySelectorAll(stati);
    let numAnim = new Array();
    for (let i = 0; i < statiEl.length; i++) {
      const e = statiEl[i];
      const num = parseInt(e.getAttribute("data-num"));
      numAnim.push(
        new CountUp(e, num, {
          duration: 3,
        })
      );
    }
    let statiTiggerEl = document.querySelector(trigger);
    if (statiTiggerEl) {
      isElementInViewport(
        statiTiggerEl,
        function () {
          numAnim.forEach((e) => {
            e.start();
          });
        },
        function () {
          numAnim.forEach((e) => {
            e.reset();
          });
        },
        "0%"
      );
    }
  };
  countUpFn("#countUpTrigger-0", ".countUpTrigger-0-item");
};

var initSwiper = function () {
  var super_menu_swiper = new Swiper(".super-menu-swiper", {
    speed: 600,
    slidesPerView: 2,
    spaceBetween: 40,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: '.super-menu-swiper-button-next',
      prevEl: '.super-menu-swiper-button-prev',
    },
    breakpoints: {
      1536: {
        spaceBetween: 60,
      }
    }
  });

  var home_banner_swiper = new Swiper(".home-banner-swiper", {
    speed: 400,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    parallax: true,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.home-banner-swiper-button-next',
      prevEl: '.home-banner-swiper-button-prev',
    },
    breakpoints: {
      1280: {
        speed: 1000,
      },
    }
  });

  var home_banner_mobile_swiper = new Swiper(".home-banner-mobile-swiper", {
    speed: 400,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    parallax: true,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  });

  var home_about_swiper = new Swiper(".home-about-swiper", {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    pagination: {
      el: ".home-about-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1440: {
        slidesPerView: 4,
      },
    }
  });

  var home_pro_swiper = new Swiper(".home-pro-swiper", {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 0,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    allowTouchMove: false,
    observer: true,
    observeParents: true,
  });

  var home_case_swiper = new Swiper(".home-case-swiper", {
    parallax: true,
    loop: true,
    speed: 400,
    slidesPerView: 1.2,
    spaceBetween: 10,
    slideToClickedSlide: true,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    navigation: {
      prevEl: ".home-case-swiper-button-prev",
      nextEl: ".home-case-swiper-button-next",
    },
    pagination: {
      el: ".home-case-swiper-pagination",
      type: 'fraction',
      clickable: true,
    },
    breakpoints: {
      768: {
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 0,
      },
      1280: {
        speed: 1000,
        slidesPerView: 'auto',
        spaceBetween: 0,
      }
    }
  });

  var home_news_swiper = new Swiper(".home-news-swiper", {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".home-news-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        speed: 400,
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      1024: {
        speed: 400,
        slidesPerView: 2.5,
        spaceBetween: 30,
      },
      1280: {
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1536: {
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 50,
      }
    }
  });

  var pro_hc_banner_swiper = new Swiper(".pro-hc-banner-swiper", {
    parallax: true,
    loop: true,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    }
  });

  var pro_hc_case_swiper = new Swiper(".pro-hc-case-swiper", {
    parallax: true,
    loop: true,
    speed: 400,
    slidesPerView: 1.2,
    spaceBetween: 10,
    slideToClickedSlide: true,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    navigation: {
      prevEl: ".pro-hc-case-swiper-button-prev",
      nextEl: ".pro-hc-case-swiper-button-next",
    },
    pagination: {
      el: ".pro-hc-case-swiper-pagination",
      type: 'fraction',
      clickable: true,
    },
    breakpoints: {
      768: {
        speed: 400,
        slidesPerView: 1.2,
      },
      1280: {
        speed: 1000,
        slidesPerView: 1,
      }
    }
  });

  var pro_hc_commit_swiper = new Swiper(".pro-hc-commit-swiper", {
    parallax: true,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 20,
    observer: true,
    observeParents: true,
    pagination: {
      el: ".pro-hc-commit-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        parallax: false,
        speed: 400,
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1280: {
        parallax: false,
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 40,
      }
    }
  });

  var pro_pc_station_swiper = new Swiper(".pro-pc-station-swiper", {
    speed: 400,
    slidesPerView: 2,
    spaceBetween: 15,
    observer: true,
    observeParents: true,
    pagination: {
      el: ".pro-pc-station-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        speed: 400,
        slidesPerView: 3.5,
        spaceBetween: 20,
      },
      1024: {
        speed: 400,
        slidesPerView: 4.5,
        spaceBetween: 20,
      },
      1280: {
        speed: 600,
        slidesPerView: 6,
        spaceBetween: 20,
      },
      1536: {
        speed: 600,
        slidesPerView: 6,
        spaceBetween: 40,
      }
    }
  });

  document.querySelectorAll('.pro-pc-tab-swiper').forEach((e, i) => {
    var pro_pc_tab_swiper = new Swiper(`.pro-pc-tab-swiper-${i}`, {
      parallax: false,
      speed: 400,
      slidesPerView: 1,
      spaceBetween: 15,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: `.pro-pc-tab-swiper-${i}-pagination`,
        clickable: true,
      },
      navigation: {
        prevEl: `.pro-pc-tab-swiper-${i}-button-prev`,
        nextEl: `.pro-pc-tab-swiper-${i}-button-next`,
      },
      breakpoints: {
        1280: {
          parallax: true,
          speed: 1000,
          slidesPerView: 1,
          spaceBetween: 40,
        }
      }
    });
  })

  var pro_de_swiper = new Swiper(".pro-de-swiper", {
    parallax: true,
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 15,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".pro-de-swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1280: {
        speed: 600,
        spaceBetween: 20,
      },
    }
  });

  var about_year_swiper = new Swiper(".about-year-swiper", {
    direction: 'horizontal',
    speed: 400,
    slidesPerView: 1.2,
    spaceBetween: 15,
    centeredSlides: true,
    allowTouchMove: true,
    observer: true,
    observeParents: true,
    breakpoints: {
      768: {
        direction: 'horizontal',
        speed: 400,
        slidesPerView: 2.2,
      },
      1024: {
        direction: 'horizontal',
        speed: 400,
        slidesPerView: 3.2,
      },
      1280: {
        direction: 'vertical',
        speed: 600,
        slidesPerView: 3,
        spaceBetween: 0,
        allowTouchMove: false,
        centeredSlides: true,
      }
    }
  });

  // init controller
  let about_process_el = document.querySelector('#about-process')
  if (about_process_el) {
    if (window.innerWidth >= 1280) {
      var controller = new ScrollMagic.Controller();
      // create a scene
      let scene = new ScrollMagic.Scene({
        triggerElement: about_process_el,
        triggerHook: '.1',
        offset: about_process_el.clientHeight * .1,
        duration: about_year_swiper.slides.length * 200,
        reverse: true
      }).setPin(about_process_el).addTo(controller);
      scene.on("update", function (event) {
        about_year_swiper.setProgress(scene.progress(), 0);
      });
    }
  }

  return { home_pro_swiper, home_case_swiper, about_year_swiper }
};

var initGSAP = function () {
  // don't forget to register plugins
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  gsap.matchMedia().add("(min-width: 1280px)", () => {
    // scroll fade
    document.querySelectorAll(".scrollFadeInUp")?.forEach((e) => {
      gsap.from(e, {
        scrollTrigger: {
          trigger: e,
          start: "top bottom",
          end: "top 70%",
          scrub: 1,
        },
        y: "100",
        autoAlpha: 0,
        duration: 1,
      });
    });
    document.querySelectorAll(".scrollFadeInLeft")?.forEach((e) => {
      gsap.from(e, {
        scrollTrigger: {
          trigger: e,
          start: "top bottom",
          end: "top 70%",
          scrub: 1,
        },
        x: "100",
        autoAlpha: 0,
        duration: 1,
      });
    });
    document.querySelectorAll(".scrollFadeInRight")?.forEach((e) => {
      gsap.from(e, {
        scrollTrigger: {
          trigger: e,
          start: "top bottom",
          end: "top 70%",
          scrub: 1,
        },
        x: "-100",
        autoAlpha: 0,
        duration: 1,
      });
    });
    document.querySelectorAll(".scrollFadeZoomIn")?.forEach((e) => {
      gsap.from(e, {
        scrollTrigger: {
          trigger: e,
          start: "top bottom",
          end: "top 70%",
          scrub: 1,
        },
        scale: 0.8,
        autoAlpha: 0,
        duration: 1,
      });
    });
    document.querySelectorAll(".scrollFadeZoomOut")?.forEach((e) => {
      gsap.from(e, {
        scrollTrigger: {
          trigger: e,
          start: "top bottom",
          end: "top 70%",
          scrub: 1,
        },
        scale: 1.2,
        autoAlpha: 0,
        duration: 1,
      });
    });

    //home-banner
    let header_h = document.querySelector("header").clientHeight
    let home_banner = document.querySelector(".home-banner");
    var tl = gsap.timeline();
    if (home_banner) {
      tl.add("start")
        // .from(
        //   ".home-banner-icon",
        //   {
        //     duration: 1.5,
        //     autoAlpha: 0,
        //     // ease: "power3.out",
        //   },
        //   "start"
        // )
        .to(
          ".home-banner-shadow",
          {
            xPercent: -80,
            duration: 1.5,
            autoAlpha: 1,
            ease: "power3.out",
          },
          "start"
        )
        .from(
          ".home-banner-img",
          {
            scale: 1.2,
            duration: 1.5,
            autoAlpha: 0,
            ease: "power3.out",
          },
          "start"
        )
        .from(
          ".home-banner-content",
          {
            xPercent: -100,
            autoAlpha: 0,
            duration: 1.5,
            delay: (i) => {
              return (i + 1) / 10;
            },
            ease: "power3.out",
          },
          "start"
        )
        .from(
          ".home-banner-bottom",
          {
            y: "2rem",
            autoAlpha: 0,
            duration: 1,
            delay: (i) => {
              return (i + 1) / 10 + 1;
            },
            ease: "power3.out",
          },
          "start"
        );
      ScrollTrigger.create({
        trigger: home_banner,
        start: `top ${header_h}`,
        end: `bottom ${header_h}`,
        scrub: true,
        pin: true,
        pinSpacing: false,
        invalidateOnRefresh: true,
        animation: gsap.to('.home-banner-container', {
          yPercent: -30,
          ease: 'none'
        })
      });
    }
  });
  // home car
  let home_car_container = document.querySelector('.home-car-container')
  if (home_car_container) {
    document.querySelectorAll(".home-car")?.forEach((e) => {
      gsap.to(e, {
        scrollTrigger: {
          trigger: home_car_container,
          start: "top 80%",
          end: "top top",
          scrub: 1,
        },
        top: home_car_container.clientHeight,
        ease: 'none'
      });
    });
  }
  let scrollTo = function (top) {
    gsap.to("html", {
      scrollTo: top || 0,
      duration: 1,
      ease: "power3.out",
    });
  };

  let scroll_posi = function (e, start, end, start_callback) {
    ScrollTrigger.create({
      trigger: e,
      start: start,
      end: end,
      onToggle: start_callback,
    })
  };
  return { scrollTo, scroll_posi };
};

var initCss = function () {
  // css
  const mt_50p = document.querySelectorAll(".mt-50p");
  for (let i = 0; i < mt_50p.length; i++) {
    const e = mt_50p[i];
    e.style.marginTop = `${e.clientHeight * 0.5}px`;
  }
  const _mt_50p = document.querySelectorAll(".-mt-50p");
  for (let i = 0; i < _mt_50p.length; i++) {
    const e = _mt_50p[i];
    e.style.marginTop = `${-e.clientHeight * 0.5}px`;
  }
  const _mr_25p = document.querySelectorAll(".-mr-25p");
  for (let i = 0; i < _mr_25p.length; i++) {
    const e = _mr_25p[i];
    e.style.marginRight = `${-e.clientWidth * 0.25}px`;
  }
  const mr_50p = document.querySelectorAll(".mr-50p");
  for (let i = 0; i < mr_50p.length; i++) {
    const e = mr_50p[i];
    e.style.marginRight = `${e.clientWidth * 0.5}px`;
  }
  const ml_50p = document.querySelectorAll(".ml-50p");
  for (let i = 0; i < ml_50p.length; i++) {
    const e = ml_50p[i];
    e.style.marginLeft = `${e.clientWidth * 0.5}px`;
  }
  const _mt_25p = document.querySelectorAll(".-mt-25p");
  for (let i = 0; i < _mt_25p.length; i++) {
    const e = _mt_25p[i];
    e.style.marginTop = `${-e.clientHeight * 0.25}px`;
  }
};

createApp({
  loading_toggle: true,
  // doc_toggle: false,
  search_toggle: false,
  contact_toggle: false,
  video_toggle: false,
  video_src: "",
  sidebar_toggle: false,
  mobile_menu_toggle: false,
  home_car: false,
  home_pro_index: 0,
  to_posi: Function,
  scroll_posi: Function,
  home_pro_swiper_fn: Function,
  home_case_swiper_fn: Function,
  about_year_swiper_fn: Function,
  about_year: 0,
  pro_hc_advan_index: 0,
  pro_pc_tab_index: 0,
  pro_de_list_index: 0,
  contact_mpa_toogle: true,
  home_case_index: 0,
  doc_toggle(val) {
    if (val) {
      document.querySelector("body").classList.add("doc--active");
    } else {
      document.querySelector("body").classList.remove("doc--active");
    }
  },
  home_pro_index_fn(val) {
    this.home_pro_index = val
    this.home_pro_swiper_fn.slideTo(val)
  },
  about_year_fn(val) {
    this.about_year = val
    this.about_year_swiper_fn.slideTo(val)
  },
  mobile_menu_toggle_fn(toggle) {
    this.doc_toggle(toggle);
    this.mobile_menu_toggle = toggle;
  },
  mobile_menu_arrow_fn(e) {
    e.currentTarget.parentElement.classList.toggle("open");
  },
  search_toggle_fn(toggle) {
    this.doc_toggle(toggle);
    this.search_toggle = toggle;
  },
  go_top() {
    this.to_posi(0);
  },
  page_posi(i) {
    let posi = `page_posi_${i}`;
    let top = getElementTop(this.$refs[posi]);
    if (window.innerWidth > 1536) {
      top = top - 200
    } else {
      top = top - 160
    }
    this.to_posi(top);
  },
  page_posi_scroll() {
    let top = 240
    if (window.innerWidth < 1535.98) {
      top = 200
    }
    Object.keys(this.$refs).forEach((key) => {
      const e = this.$refs[key];
      if (e.dataset.ref == "page_posi") {
        this.scroll_posi(e, `top ${top}`, `top ${top / 2}`, () => {
          this.pro_de_list_index = e.dataset.page_posi_index
        })
      }
    })
  },
  video_toggle_fn(e, toggle) {
    if (toggle) {
      this.video_src = e.currentTarget.dataset.src;
    }
    this.doc_toggle(toggle);
    this.video_toggle = toggle;
  },
  contact_toggle_fn(toggle) {
    this.doc_toggle(toggle);
    this.contact_toggle = toggle;
  },
  self_active_toggle(e) {
    e.currentTarget.classList.toggle("active");
  },
  mounted() {
    this.loading_toggle = false;
    // init
    initCountUp();
    let swiper_obj = initSwiper();
    this.home_pro_swiper_fn = swiper_obj.home_pro_swiper
    this.home_case_swiper_fn = swiper_obj.home_case_swiper
    this.about_year_swiper_fn = swiper_obj.about_year_swiper
    let that = this
    this.home_case_swiper_fn.on('slideChange', function () {
      that.home_case_index = this.realIndex
    })
    this.about_year_swiper_fn.on('slideChange', function () {
      that.about_year = this.realIndex
    })
    let gsap_obj = initGSAP();
    this.to_posi = gsap_obj.scrollTo;
    this.scroll_posi = gsap_obj.scroll_posi;
    initCss();
    console.info("Design by HQT huaqiutong.com");
    // scroll
    let ticking = false;
    let scrollY = window.scrollY;
    window.addEventListener("scroll", () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // do something...
          if (scrollY > 200) {
            this.sidebar_toggle = true;
          } else {
            this.sidebar_toggle = false;
          }
          if (window.scrollY > scrollY) {
            this.home_car = true;
          } else {
            this.home_car = false;
          }
          scrollY = window.scrollY;
          ticking = false;
        });
        ticking = true;
      }
    });
    this.page_posi_scroll()
    SmoothScroll({
      animationTime: 600,
    });
  }
}).mount("#app");
